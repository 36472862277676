<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
      <template slot="title">
        <strong v-if="action === 'create'">Form Kelas</strong>
        <strong v-if="action === 'update'">Update Kelas</strong>
        <a-button class="btn btn-danger ml-2" @click="resetForm"
          >Back</a-button
        >
                <a-button
                  :class="action === 'update' ? 'btn btn-warning ml-2' : 'btn btn-primary ml-2'"
                  @click="handleSubmit"
                  >{{ action === 'update' ? 'Save Changes' : 'Submit' }}</a-button
                >
      </template>
          <div class="row">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label>Name</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-input placeholder="Name" v-model="modalInput.name" style="width: 200px;"></a-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label>Description</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-input placeholder="Description" v-model="modalInput.description" style="width: 50%;"></a-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label>Guru</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-select
                class=""
                show-search
                placeholder="Pilih Guru"
                v-model="modalInput.teacher_id"
                option-filter-prop="children"
                :filter-option="filterOption"
                style="width: 250px"
              >
                <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                <a-select-option
                  style="width: 100%"
                  v-for="d in masterTeachers"
                  :key="d.id"
                >
                  <!-- {{ formatDate(d.start_date) + " - " + formatDate(d.end_date) }} -->
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label>Academic Year</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-select
                class=""
                show-search
                placeholder="Pilih Academic Year"
                v-model="modalInput.academic_year_id"
                option-filter-prop="children"
                :filter-option="filterOption"
                style="width: 250px"
              >
                <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                <a-select-option
                  style="width: 100%"
                  v-for="d in masterAcademicYear"
                  :key="d.id"
                >
                  <!-- {{ formatDate(d.start_date) + " - " + formatDate(d.end_date) }} -->
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label>Tambahkan Siswa</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-select
                class=""
                show-search
                placeholder="Tambah Siswa"
                v-model="mask.student_id"
                option-filter-prop="children"
                :filter-option="filterOption"
                style="width: 250px"
                @change="addToTable"
              >
                <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                <a-select-option
                  style="width: 100%"
                  v-for="d in masterStudents"
                  :key="d.id"
                >
                  <!-- {{ formatDate(d.start_date) + " - " + formatDate(d.end_date) }} -->
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <strong for="">Data siswa</strong>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <a-table
            :scroll="{ y: 300 }"
            :columns="table.column"
            :dataSource="table.datatable"
            :pagination="false"
            size="small"
            :customRow="customRow"
          >
            <template slot="actionButton" slot-scope="text, record, i">
              <!-- <a-button
                :class="record.checked ? 'btn btn-outline-danger' : 'btn btn-outline-primary'"
                @click="!record.checked ? selectData(record) : removeData(i)"
                >
                {{ record.checked ? 'Unselect' : 'Select' }}
                </a-button
              > -->
              <a href="javascript:void(0)">
                <a-icon type="delete" class="text-danger" @click="removeStudentFromList(i)"/>
              </a>
            </template>
        <span slot="numberFormat" slot-scope="text">
          {{ numberFormat(text) }}
        </span>
        <span slot="dateFormat" slot-scope="text">
          {{ formatDate(text) }}
        </span>
        <span slot="dateFormat" slot-scope="text">
          {{ formatDate(text) }}
        </span>
          </a-table>
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  created () {
    this.getMaster()
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Data'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Data'
    }
  },
  data() {
    return {
      editdata: JSON.parse(
        localStorage.getItem('schoolmanagementkelas'),
      ),
      action: 'create',
      masterTeachers: [],
      masterStudents: [],
      masterAcademicYear: [],
      modalInput: {
        name: '',
        description: '',
        teacher_id: '',
        academic_year_id: '',
        students: [],
      },
      mask: {
        student_id: '',
      },

      table: {
        column: [
          {
            title: '#',
            // dataIndex: 'actionButton',
            scopedSlots: { customRender: 'actionButton' },
            width: 50,
          },
          {
            title: 'Nama',
            dataIndex: 'name',
            scopedSlots: { customRender: '' },
            // width: 150,
          },
        ],
        datatable: [],
      },
    }
  },
  methods: {
    moment,
    addToTable() {
      var exist = this.table.datatable.findIndex(x => x.id === this.mask.student_id)
      if (exist < 0) {
        var target = this.masterStudents.findIndex(x => x.id === this.mask.student_id)
        if (target >= 0) {
          var fd = {
            id: this.masterStudents[target].id,
            name: this.masterStudents[target].name,
          }
          this.table.datatable.push(fd)
          lou.shownotif('Added', 'Data Siswa sudah ditambahkan!', 'success')
          this.mask.student_id = ''
        } else {
          lou.shownotif('Error', 'Data siswa tidak ditemukan', 'error')
        }
      } else {
        lou.shownotif('Data', 'Data Siswa sudah ada dilist!')
      }
    },
    removeStudentFromList(i) {
      this.table.datatable.splice(i, 1)
    },
    async getEditData() {
      // var res = await lou.customUrlGet2('registrasi/simpanan', { where: { id: this.editdata.id } })
      // console.log('this.editdata', this.editdata)
      this.modalInput = this.$g.clone(this.editdata)
      // console.log('this.editdata', this.editdata)
      // this.maskInput.cabang = this.editdata.kode.slice(0, this.editdata.kode.indexOf('.'))
      // this.maskInput.rekode = this.editdata.kode.slice(this.editdata.kode.indexOf('.') + 1, this.editdata.kode.length)
      // this.table.datatable = this.editdata.details
      // var tempdata = []
      // console.log('this.table.datatable', this.table.datatable)
      // console.log('this.editdata.details', this.editdata.details)
      this.editdata.students.forEach(element => {
        this.table.datatable.push({ id: element.id, name: element.name })
      })
      // this.modalInput.details.forEach(element => {
      //   element.payment_schedule_id = element.payment_schedule_id
      // })
      // this.filterJadwal()
      // console.log('this.editdata', this.editdata)
      // setTimeout(() => {
      //   this.modalInput.kodya = this.editdata.kodya
      //   this.kotaChange()
      // }, 100)
      // setTimeout(() => {
      //   this.modalInput.kecamatan = this.editdata.kecamatan
      //   this.kecamatanChange()
      // }, 300)
      // setTimeout(() => {
      //   this.modalInput.kelurahan = this.editdata.kelurahan
      //   this.kelurahanChange()
    },
    async getMaster() {
      this.masterSchedule = []
      this.masterTeachers = []
      this.masterPaymentType = []
      var res1 = await lou.customUrlGet2('sekolah/student')
      var res2 = await lou.customUrlGet2('sekolah/teacher')
      var res3 = await lou.customUrlGet2('sekolah/academic_year')
      if (res1) {
        this.masterStudents = res1.data
        this.masterTeachers = res2.data
        this.masterAcademicYear = res3.data
      }
    },
    // async filterJadwal() {
    //   var res = await lou.customUrlGet2('sekolah/class/schedule/' + this.modalInput.student_id)
    //   console.log('res', res)
    //   if (res) {
    //     this.table.datatable = res.data

    //     this.table.datatable.forEach(element => {
    //       // element.payment_type_name = this.findMaster('masterPaymentType', 'id', element.payment_type_id, 'name')
    //       // element.academic_year = this.findMaster('masterAcademicYear', 'id', element.academic_year_id, 'name')
    //       element.checked = false
    //     })
    //   }
    //   // var target = this.masterSchedule.filter(x => x.student_id === this.modalInput.student_id)
    //   // if (target.length !== 0) {
    //   //   this.table.datatable = target
    //   //   this.table.datatable.forEach(element => {
    //   //     element.payment_type_name = this.findMaster('masterPaymentType', 'id', element.payment_type_id, 'name')
    //   //     element.academic_year = this.findMaster('masterAcademicYear', 'id', element.academic_year_id, 'name')
    //   //     element.checked = false
    //   //   })
    //   // } else {
    //   //   this.table.datatable = []
    //   // }
    // },
    selectData(record) {
      var findD = this.modalInput.details.findIndex(x => x.payment_schedule_id === record.payment_schedule_id)
      console.log('findD', findD)
      if (findD >= 0) {
        this.ext()
      } else {
        record.checked = true
        this.modalInput.details.push(record)
        this.ext()
      }
      // console.log('this.modalInput.details', this.modalInput.details)
    },
    removeData(index) {
      // console.log('index', index)
      // console.log('this.modalInput.details[index]', this.modalInput.details)
      this.table.datatable[index].checked = false
      var targetIn = this.modalInput.details.findIndex(x => x.payment_schedule_id === this.table.datatable[index].payment_schedule_id)
      if (targetIn >= 0) {
        this.modalInput.details.splice(targetIn, 1)
      }
      this.ext()
    },
    ext() {
      const countOccurrences = arr => arr.reduce((acc, item) => {
        acc[item] = (acc[item] || 0) + 1
        return acc
      }, {})
      var sum = 0
      var notes = []
      this.modalInput.details.forEach(element => {
        element.pembayaran = element.amount
        sum += element.amount
        notes.push(element.payment_type_name)
      })
      var removedup = countOccurrences(notes)
      var getkey = Object.keys(removedup)
      var therealnote = ''
      getkey.forEach(element => {
        // console.log('element', element)
        therealnote += therealnote !== '' ? removedup[element] <= 1 ? ' + ' + element : ' + ' + element + removedup[element] : removedup[element] <= 1 ? element : element + removedup[element]
      })
      // console.log('this.modalInput.details', this.modalInput.details)
      this.mask.amount = this.numberFormat(sum)
      this.modalInput.amount = sum
      this.modalInput.notes = therealnote
    },
    async handleSubmit() {
      var fd = {
        name: this.modalInput.name,
        description: this.modalInput.description,
        teacher_id: this.modalInput.teacher_id,
        academic_year_id: this.modalInput.academic_year_id,
        students: [],
      }
      this.table.datatable.forEach(element => {
        var p = {
          id: element.id,
        }
        fd.students.push(p)
      })
      var res = false
      if (this.action === 'update') {
        fd.id = this.editdata.id
        res = await lou.customUrlPut2('sekolah/class', fd)
      } else {
        res = await lou.customUrlPost2('sekolah/class', fd)
      }
      if (res) {
        this.resetForm()
      }
    },
    resetForm() {
      localStorage.removeItem('schoolmanagementkelas')
      this.modalInput = {
        name: '',
        description: '',
        teacher_id: '',
        academic_year_id: '',
        students: [],
      }
      this.$router.push('/schoolmanagement/kelas/data')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },

    //
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    findMaster(mastername, key, val, tokey) {
      var ret = ''
      var trgt = this[mastername].findIndex((x) => x[key] === val)
      if (trgt >= 0) {
        // if (mastername === 'masterSchedule') {
        console.log('this[mastername][trgt][tokey]', this[mastername][trgt][tokey])
        // }
        ret = this[mastername][trgt][tokey]
      } else {
        ret = 'Data tidak ditemukan!'
      }
      return ret
    },
    numberFormat(val) {
      var ret = lou.curency(val)
      // console.log('ret', ret)
      if (ret === 'NaN') {
        return 0
      } else {
        return ret
      }
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
